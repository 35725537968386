/**
 * Main.js
 * Require all the needed modules
 */

var $ = window.$ = window.jQuery = require('jquery');
require('slick-carousel');
require('jquery-validation');
require('fancybox')($);

/**
 * Active Links
 */

var page = $('body').data('page');

$('#' + page).addClass('is-active');

/**
 * Btn Mobile
 */

$('.js-btn-menu-mobile').on('click', function (e) {
  $(this).toggleClass('is-active');
  $('.js-main-nav').toggleClass('is-active');
});

/**
 * Banner
 */

var $banner = $('.js-banner');

$banner.find('a').each(function () {
  var $slide = $(this);
  var img = $slide.data('img');
  $slide.css({
    backgroundImage: 'url(\'' + img + '\')'
  });
});

$banner.slick({
  dots: true,
  prevArrow: '.js-banner-prev',
  nextArrow: '.js-banner-next',
  autoplay: true,
  autoplaySpeed: 5000
});

/**
 * Validate
 */

$.validator.messages.required = 'Campo obrigatório';
$.validator.messages.email = 'E-mail inválido';

$('.js-form-newsletter').validate({
  rules: {
    'data[name]': 'required',
    'data[email]': 'required'
  },

  errorPlacement: function (err, input) {
    err.appendTo(input.parents('.newsletter-field'));
  }
});

$('.js-form-contact').validate({
  rules: {
    'data[name]': 'required',
    'data[email]': 'required',
    'data[phone]': 'required',
    'data[message]': 'required'
  }
});

/**
 * Back Button
 */

$('.js-go-back').on('click', function (e) {
  e.preventDefault();
  window.history.go(-1);
  return false;
});

/**
 * Fancybox
 */

$('.js-fancy').fancybox();
